<template lang="pug">
FLocaleSelect.FcwLocaleSelect(
  v-if="locales.length > 1"
  :model-value="locale"
  :locale-codes="locales"
  color="transparent"
  text-color="secondary--light-1"
  focus-color="transparent"
  border-color="secondary--light-1"
  focus-border-color="secondary--light-1"
  outline-color="secondary"
  options-menu-color="neutral--light-5"
  option-text-color="secondary"
  selected-option-color="neutral--light-3"
  selected-option-text-color="secondary"
  menu-width="200"
  size="medium"
  hide-hint
  :option-label-format="formatLabelOption"
  @update:model-value="onLocaleChange"
)
</template>

<style lang="stylus">
.FcLocaleSelect__selectedValue
  display flex

.FcLocaleSelect__option__img
  margin-right rem(12)
  height rem(24)
</style>

<script setup lang="ts">
import { locales } from '@/config/locales';

const { t, locale, setLocale } = useI18n();

const switchLocalePath = useSwitchLocalePath();

function onLocaleChange(newLocale: string | null): void {
  if (!newLocale) return;

  setLocale(newLocale);
  navigateToLocalePath(newLocale);
}

function navigateToLocalePath(value: string): void {
  navigateTo(switchLocalePath(value));
}

function formatLabelOption(locale: string): string {
  return t(`app__lang_${locale ?? ''}`);
}
</script>
